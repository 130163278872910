

@font-face {
  font-family: 'chicagoflfregular';
  src: url('../fonts/chicagoflf-webfont.woff2') format('woff2'),
       url('../fonts/chicagoflf-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  font-family: 'chicagoflfregular', cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

th, table 
{
  margin: 0;
  font-family: 'chicagoflfregular', cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    transform: translateY(90%);
}


/* #CUSTOM */
#fightsplash {
  z-index: 1000;
    position: absolute;
    opacity: 0;
    animation: entrance-fade-in 1.0s cubic-bezier(0.86, 0, 0.07, 1);
    animation-fill-mode: forwards;
}

#letsfight {
  z-index: 1000;
    position: absolute;
    opacity: 0;
    animation: entrance-fade-in2 1.0s cubic-bezier(0.86, 0, 0.07, 1);
    animation-fill-mode: forwards;
}

/* #villian {
  z-index: 1000;
  position: absolute;
  opacity: 0;
  animation: entrance-fade-in-right 1.0s cubic-bezier(0.86, 0, 0.07, 1);
  animation-fill-mode: forwards;
} */

#flashbang
{
    opacity: 0;
    animation: fadeIn 0.2s cubic-bezier(0.86, 0, 0.07, 1);
    animation-fill-mode: forwards;
}


@keyframes entrance-fade-in2 {
  from {
    opacity: 0;
    transform: translateY(0%);
  }
  to {
    opacity: 1;
    transform: translateY(75%);
  }
}

@keyframes entrance-fade-in {
  from {
    opacity: 0;
    transform: translateY(0%);
  }
  to {
    opacity: 1;
    transform: translateY(70%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 
@keyframes entrance-fade-in-right {
  from {
    opacity: 0;
    transform: translateX(%);
  }
  to {
    opacity: 1;
    transform: translateX(80%);
  }
} */



